import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import MockupHeader from '../components/mockup/header';
import MockupContainer from '../components/mockup/mockupContainer';
import { closeIcon } from "../lottie/animations";

const OrderFailed = ({id}) => {

    const [email, setEmail] = useState("");

    useEffect(() => {
        // check status of render
        axios({
            method: 'get',
            url: process.env.GATSBY_MOCKUP_API + '/renders/' + id 
        })
        .then((res) => {
            if(res.data.render.email) {
                setEmail(res.data.render.email);
            }
        });
    }, []);

    const lottieOptions = {
        loop: false,  
        autoplay: true,
        animationData: closeIcon
    };
    return(
        <MockupContainer>
            <MockupHeader hideSteps={true} />
            <div className="flex justify-center items-center flex-col">
                <div className="w-full bg-gray-100 p-10">
                    <div className="text-center mb-24">
                        <Lottie
                            options={lottieOptions}
                            height={100}
                            width={325}
                        />
                        <h1 className="text-3xl font-semibold my-1">Order Failed</h1>
                        <p className="text-gray-600 mb-10">Your order no. is: <span className="font-semibold">{id}</span></p>
                        <p className="text-gray-600 mb-10">It looks like something has gone wrong with your order.<br/>We have been notified and will be in contact shortly on the email address provided during checkout <b>{email}</b></p>
                        <p className="text-gray-600 mb-10">Thank you for your patience<br/><a className={"text-indigo-700 hover:underline"} href={"mailto:help@editsquare.com"}>help@editsquare.com</a></p>
                    </div>
                </div>
            </div>
        </MockupContainer>
    )
};

export default OrderFailed;